import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Home } from './Pages/Home/Index';

function App() {
  return (
    <>
      <Home />
    </>

  );
}

export default App;
