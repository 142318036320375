import React from 'react'

export const Home = () => {
    return (<>


        <div id="preloader">
            <div id="loader" className="dots-jump">
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>

        <header className="s-header">
            <div className="row">
                <div className="header-logo">
                    <a className="site-logo" href="index.html">
                        <img src="assets/images/logo.png" alt="Homepage" />

                    </a>
                </div>
                <nav className="header-nav-wrap">
                    <ul className="header-nav">
                        <li className="current"><a className="smoothscroll" href="#home" title="home">Inicio</a></li>
                        <li><a className="smoothscroll" href="#about" title="about">Sobre nós</a></li>
                        <li><a className="smoothscroll" href="#services" title="services">Serviços</a></li>
                        <li><a className="smoothscroll" href="#works" title="works">Projectos</a></li>
                        <li><a className="smoothscroll" href="#contact" title="contact">Contactos</a></li>
                    </ul>
                </nav>
                <a className="header-menu-toggle" href="#0">
                    <span className="header-menu-icon"></span>
                </a>
            </div>
        </header>

        <section id="home" className="s-home page-hero target-section" data-parallax="scroll" data-image-src="assets/images/hero-bg.jpg" data-natural-width="3000" data-natural-height="2000" data-position-y="center">
            <div className="shadow-overlay"></div>
            <div className="home-content">
                <div className="row home-content__main">
                    <h1>
                        Ao longo de 7 anos, nossa empresa 
                        cresceu para ser uma das maiores e mais
                        confiáveis do sector
                    </h1>
                    <div className="home-content__button">
                        <a href="#about" className="smoothscroll btn btn-animatedbg">
                            Saiba mais
                        </a>
                    </div>
                    <div className="home-content__video">
                        <a className="video-link" href="https://player.vimeo.com/video/117310401?color=01aef0&amp;title=0&amp;byline=0&amp;portrait=0" data-lity>
                            <span className="video-icon"></span>
                            <span className="video-text">Play Video</span>
                        </a>
                    </div>
                </div>
                <div className="home-content__scroll">
                    <a href="#about" className="scroll-link smoothscroll">
                        Scroll
                    </a>
                </div>
            </div>
            <ul className="home-social">
                <li>
                    <a href="#0"><i className="fab fa-facebook-f" aria-hidden="true"></i><span>Facebook</span></a>
                </li>
                <li>
                    <a href="#0"><i className="fab fa-twitter" aria-hidden="true"></i><span>Twiiter</span></a>
                </li>
                <li>
                    <a href="#0"><i className="fab fa-instagram" aria-hidden="true"></i><span>Instagram</span></a>
                </li>
                <li>
                    <a href="#0"><i className="fab fa-behance" aria-hidden="true"></i><span>Behance</span></a>
                </li>
                <li>
                    <a href="#0"><i className="fab fa-dribbble" aria-hidden="true"></i><span>Dribbble</span></a>
                </li>
            </ul>
        </section>

        <section id="about" className="s-about target-section">
            <div className="row section-header" data-aos="fade-up">
                <div className="col-full">
                    <h3 data-num="01" className="subhead">QUEM SOMOS</h3>
                    <h1 className="display-1">
                        A expertise de nossos consultores garante a prestação de serviços de
                        qualidade, em linha com as mais modernas técnicas e metodologias, em
                        todas áreas em que operamos trazendo para os clientes a vanguarda
                        existente no mundo corporativo em Angola e no exterior.
                    </h1>
                    <p className="lead">
                        Nossa empresa surgiu para atender um mercado cada vez maior de
                        pessoas singulares e empresas que necessitam de serviços de qualidade
                        para alcançarem seus objetivos e se diferenciarem no mercado moderno
                        cada vez mais competitivo.
                    </p>
                </div>
            </div>
            <div className="row about-process block-1-2 block-tab-full">
                <div className="col-block item-process" data-aos="fade-up">
                    <div className="item-process__header item-process__header--planning">
                        <h3>A nossa missão</h3>
                    </div>
                    <p>
                        Fornecer soluções de consultoria, contabilidade e assessoria fiscal de alta
                        qualidade para ajudar nossos clientes a alcançar seus objetivos financeiros
                        e de negócios.
                    </p>
                </div>
                <div className="col-block item-process" data-aos="fade-up">
                    <div className="item-process__header item-process__header--branding">
                        <h3>Os nossos Valores</h3>
                    </div>
                    <p>
                        Nossos valores fundamentais incluem integridade, excelência, trabalho em
                        equipe, inovação e responsabilidade social. Acreditamos que esses valores
                        são essenciais para fornecer serviços de alta qualidade e construir
                        relacionamentos duradouros com nossos clientes.
                    </p>
                </div>
            </div>
            <div className="row about-process block-1-2 block-tab-full">
                <div className="col-block item-process" data-aos="fade-up">
                    <img src='assets/images/pics/image-1.jpg' />
                </div>
                <div className="col-block item-process" data-aos="fade-up">
                    <div className="item-process__header item-process__header--documentation">
                        <h3>A NOSSA HISTÓRIA</h3>
                    </div>
                    <p>
                        A LINEAUDIT foi criada com o objetivo de trazer
                        uma forma diferenciada e personalizada de
                        trabalhar, fortalecendo no mercado local.
                        Contou para isso com o expertise de sua equipe e
                        parceiros que actuavam em diversas instituições
                        multinacionais.
                    </p>
                    <p>
                        Essas empresas deram o primeiro grande contrato
                        e foi o cartão de visita para o início de uma história
                        de sucesso.
                    </p>
                    <p>
                        Nunca nos afastamos do nosso propósito principal e
                        compromisso de longo prazo: Oferecer serviços com o
                        mais alto padrão de qualidade e excelência
                    </p>
                    <p>
                        Nossos consultores são altamente motivados a contribuir
                        para o sucesso de nossos clientes.
                    </p>
                    <p>
                        A multidisciplinaridade é uma marca da nossa atuação, o
                        que nos levou a desenvolver um conhecimento profundo
                        dos setores público e privado.
                    </p>
                    <p>
                        Para nós, cada cliente é único.
                    </p>
                </div>
            </div>
        </section>

        <section id="services" className="s-services target-section">
            <div className="row section-header" data-aos="fade-up">
                <div className="col-full">
                    <h3 data-num="02" className="subhead">Oque nós fazemos</h3>
                    <h1 className="display-1 display-1--light">
                        Our services are tailored to make your business grow and stand out.
                    </h1>
                    <p className="lead lead-light">
                        Explore os nossos serviços abaixo e descubra como podemos ajudar a impulsionar o sucesso e o crescimento do seu negócio, enquanto garantimos a conformidade fiscal e a transparência contabilística. Estamos aqui para ser o seu parceiro confiável no mundo financeiro, oferecendo expertise, compromisso e excelência em cada serviço que prestamos.
                    </p>
                </div>
            </div>
            <div className="row services-list block-1-3 block-m-1-2 block-tab-full">
                <h1 style={{ color: "#FFF" }}>: : CONSULTORIA CONTABILÍSTICA</h1>
            </div>
            <div className="row services-list block-1-3 block-m-1-2 block-tab-full">
                <div className="col-block item-service" data-aos="fade-up">
                    <h4>Acompanhamento Contabilístico das Atividades da Empresa</h4>
                    <p>
                        Nossa equipe especializada oferece um serviço abrangente de acompanhamento contabilístico para garantir que as atividades financeiras da sua empresa sejam registradas e gerenciadas de forma precisa e eficiente. Desde a organização e registro de transações até a elaboração de relatórios financeiros, estamos comprometidos em fornecer informações contabilísticas claras e atualizadas para ajudar na tomada de decisões estratégicas.
                    </p>
                </div>
                <div className="col-block item-service" data-aos="fade-up">
                    <h4>Fechos Mensais das Contas</h4>
                    <p>
                        Realizamos fechos mensais das contas da sua empresa para garantir que os registros contabilísticos estejam precisos e atualizados. Essa prática é essencial para monitorar o desempenho financeiro, identificar tendências e ajustar estratégias conforme necessário. Nosso objetivo é fornecer fechos de contas pontuais e transparentes, oferecendo uma visão clara da saúde financeira da sua empresa.
                    </p>
                </div>
                <div className="col-block item-service" data-aos="fade-up">
                    <h4>Reportes Periódicos de Desempenho Económico e Financeiro da Empresa</h4>
                    <p>
                        Preparamos reportes periódicos detalhados sobre o desempenho económico e financeiro da sua empresa, destacando indicadores-chave e apresentando rácios financeiros relevantes. Esses relatórios fornecem uma análise aprofundada da situação financeira da empresa, ajudando na identificação de áreas de melhoria e no desenvolvimento de estratégias para impulsionar o crescimento e a rentabilidade.
                    </p>
                </div>
                <div className="col-block item-service" data-aos="fade-up">
                    <h4>Reportes Mensais das Obrigações Tributárias à AGT</h4>
                    <p>
                        Cumprimos com as obrigações tributárias da sua empresa, preparando e enviando reportes mensais à Administração Geral Tributária (AGT). Isso inclui a extracção da Declaração de Conformidade Tributária através do portal da AGT, garantindo que sua empresa esteja em conformidade com todas as regulamentações fiscais e evitando penalidades desnecessárias.
                    </p>
                </div>
                <div className="col-block item-service" data-aos="fade-up">
                    <h4>Extracção no Portal, a Declaração de Conformidade Tributária</h4>
                    <p>
                        Utilizando nossa expertise e acesso aos sistemas online da Administração Geral Tributária (AGT), realizamos a extração da Declaração de Conformidade Tributária para sua empresa. Esse documento é fundamental para comprovar a regularidade fiscal da empresa perante as autoridades tributárias, garantindo tranquilidade e segurança jurídica.
                    </p>
                </div>
                <div className="col-block item-service" data-aos="fade-up">
                    <h4>Acompanhamento e Tratamento das Notificações Fiscais</h4>
                    <p>
                        Oferecemos um serviço dedicado de acompanhamento e tratamento das notificações fiscais recebidas pela sua empresa. Nossa equipe analisa cuidadosamente cada notificação, toma as medidas necessárias para resolver qualquer problema ou discrepância e garante que sua empresa esteja em conformidade com as obrigações fiscais em vigor.
                    </p>
                </div>


            </div>
        </section>
        <section id="services" className="s-works target-section" style={{ paddingTop: '1em' }}>
            <div className="row services-list block-1-3 block-m-1-2 block-tab-full">
                <h1 >: : ASSESSORIA FISCAL</h1>
            </div>
            <div className="row services-list block-1-2 block-m-1-2 block-tab-full">
                <div className="col-block item-service" data-aos="fade-up">
                    <h4>O Acompanhamento dos Temas de Natureza Fiscal da Empresa</h4>
                    <p>
                        Em um ambiente empresarial cada vez mais complexo, é essencial manter-se atualizado e em conformidade com as constantes mudanças fiscais. Oferecemos um serviço abrangente de acompanhamento dos temas de natureza fiscal da sua empresa, fornecendo orientação especializada para garantir que você esteja ciente das regulamentações fiscais relevantes e das implicações para o seu negócio. Nosso compromisso é oferecer suporte contínuo, ajudando a minimizar riscos e maximizar oportunidades dentro do contexto fiscal.
                    </p>
                </div>
                <div className="col-block item-service" data-aos="fade-up">
                    <h4>Reportes Mensais das Obrigações Tributárias à AGT</h4>
                    <p>
                        Cumprir com as obrigações tributárias é fundamental para o funcionamento suave e legal da sua empresa. Nossa equipe prepara e envia reportes mensais detalhados das obrigações tributárias à Administração Geral Tributária (AGT), garantindo que sua empresa esteja em conformidade com as leis fiscais vigentes. Essa prática não só ajuda a evitar penalidades desnecessárias, mas também oferece tranquilidade, permitindo que você se concentre no crescimento do seu negócio
                    </p>
                </div>
                <div className="col-block item-service" data-aos="fade-up">
                    <h4>Acompanhamento e Tratamento das Notificações Fiscais</h4>
                    <p>
                        Notificações fiscais podem surgir a qualquer momento e exigem uma resposta rápida e eficaz. Estamos aqui para oferecer acompanhamento e tratamento ágil das notificações fiscais recebidas pela sua empresa. Nossa equipe analisa cuidadosamente cada notificação, identifica as melhores estratégias de resposta e garante que todas as questões sejam resolvidas de maneira oportuna e satisfatória, proporcionando segurança e conformidade.
                    </p>
                </div>
                <div className="col-block item-service" data-aos="fade-up">
                    <h4>Aconselhamento e Alertas ao Cumprimento de Obrigações Declarativas</h4>
                    <p>
                        Manter-se atualizado sobre as obrigações declarativas é essencial para evitar problemas legais e financeiros. Oferecemos aconselhamento especializado e alertas oportunos para garantir que sua empresa cumpra todas as obrigações declarativas relevantes. Nossa equipe está aqui para orientá-lo sobre os prazos, requisitos e procedimentos necessários, ajudando a evitar multas e penalidades associadas ao não cumprimento das obrigações fiscais e declarativas.
                    </p>
                </div>

            </div>
        </section>

        <section id="services" className="s-services target-section" style={{ paddingTop: '1em' }}>
            <div className="row services-list block-1-3 block-m-1-2 block-tab-full">
                <h1 style={{ color: "#FFF" }}>: : CONSULTORIA</h1>
            </div>
            <div className="row section-header" data-aos="fade-up">
                <div className="col-full">
                    <p >
                        O serviço de Consultoria/Assessoria oferecido pela Lineaudit, consiste, na Análise e Diagnóstico da Empresa e da
                        sua posição no mercado aprofundados sobre as medidas necessárias para reduzir custos e aumentar a
                        produtividade contribuindo com o negócio, proporcionando aumento da eficacia.
                    </p>
                </div>
                <div className="col-full">
                    <ol>
                        <li>Análise da Indústria (Ramo de Negócio) na qual a empresa actua, de acordo com a teoria das forças competitivas (Porter);</li>
                        <li>Análise SWOT (Pontos fortes, pontos fracos, oportunidades e desafios) da empresa;</li>
                        <li>Análise dos Recursos da Empresa. Identificação dos Recursos Valiosos, capazes de constituírem diferenciais competitivos, que levam à vantagem competitiva;</li>
                        <li>Análise do Contrato Social e proposição de ajustes/inclusões para futura revisão</li>
                        <li>Estabelecimento de Indicadores de Desempenho para as diversas áreas de Administração do negócio;</li>
                        <li> Elaboração ou Reestruturação do Plano de Negócio- Dependendo do caso em apreço (Plano inicial - “Start-up Plan”, que irá definir as linhas gerais de uma nova ideia de negócio ou Plano de reestruturação - “Turnaround-Plan” focando-se na identificação dos problemas e nas propostas de solução através de alterações à estratégia, visando a viabilização da empresa.</li>
                        <li> Gestão de programas e projectos;</li>
                        <li> Formações Profissionais.</li>
                    </ol>
                </div>
            </div>
            <div className="row services-list block-1-3 block-m-1-2 block-tab-full">
                <h1 style={{ color: "#FFF" }}>: : APOIO JURÍDICO</h1>
            </div>
            <div className="row services-list block-1-3 block-m-1-2 block-tab-full">
                <div className="col-block item-service" data-aos="fade-up">
                    <h4>Abertura de Empresa</h4>
                    <p>
                        Orientamos e auxiliamos na abertura de empresas, garantindo que todos os procedimentos legais sejam seguidos de acordo com a legislação vigente. Desde a escolha do tipo jurídico mais adequado até a obtenção de licenças e autorizações necessárias, nossa equipe jurídica especializada oferece suporte completo durante o processo de abertura.
                    </p>
                </div>
                <div className="col-block item-service" data-aos="fade-up">
                    <h4>Alteração da Estrutura Societária, Aumento de Capital e Pacto Social</h4>
                    <p>
                        Prestamos assistência jurídica em processos de alteração da estrutura societária, aumento de capital e revisão do pacto social da empresa. Nosso objetivo é garantir que todas as mudanças sejam realizadas de acordo com as normas legais e em conformidade com os interesses dos sócios e da empresa.
                    </p>
                </div>
                <div className="col-block item-service" data-aos="fade-up">
                    <h4>Alteração de Enquadramento da Empresa e Transformação de Tipo Jurídico</h4>
                    <p>
                        Auxiliamos empresas em processos de legalização, garantindo que todas as obrigações legais sejam cumpridas e que a empresa opere de acordo com as normas estabelecidas. Desde a obtenção de alvarás e licenças até a regularização de pendências, nossa equipe está comprometida em garantir a legalidade e o bom funcionamento da empresa.
                    </p>
                </div>
                <div className="col-block item-service" data-aos="fade-up">
                    <h4>Legalização de Empresas</h4>
                    <p>
                        Auxiliamos empresas em processos de legalização, garantindo que todas as obrigações legais sejam cumpridas e que a empresa opere de acordo com as normas estabelecidas. Desde a obtenção de alvarás e licenças até a regularização de pendências, nossa equipe está comprometida em garantir a legalidade e o bom funcionamento da empresa.
                    </p>
                </div>
                <div className="col-block item-service" data-aos="fade-up">
                    <h4>Encerramento/Liquidação de Empresas</h4>
                    <p>
                        Prestamos assistência jurídica em processos de encerramento e liquidação de empresas, orientando os proprietários sobre os procedimentos legais necessários e representando seus interesses perante as autoridades competentes. Nosso objetivo é garantir que o encerramento seja realizado de forma transparente e em conformidade com a legislação vigente.
                    </p>
                </div>
                <div className="col-block item-service" data-aos="fade-up">
                    <h4>Assessoria/Consultoria Jurídica na Área Imobiliária e Registo Predial</h4>
                    <p>
                        Oferecemos assessoria e consultoria jurídica especializada na área imobiliária e de registo predial, auxiliando clientes em questões relacionadas a contratos de compra e venda, locação, regularização de imóveis e registro de propriedade. Nossa equipe jurídica está disponível para fornecer orientação e assistência em todas as etapas desses processos.
                    </p>
                </div>
                <div className="col-block item-service" data-aos="fade-up">
                    <h4>Elaboração e Redação de Cartas, Estabelecimento de Contatos por Parte de Juristas</h4>
                    <p>
                        Providenciamos a elaboração e redação de cartas legais, bem como o estabelecimento de contatos por parte de juristas especializados para acompanhar processos em atraso. Além disso, realizamos o envio de cartas de cobrança e outros documentos legais conforme necessário, garantindo o cumprimento de prazos e a defesa dos interesses dos nossos clientes
                    </p>
                </div>
                <div className="col-block item-service" data-aos="fade-up">
                    <h4>Mediação de Conflitos, Esclarecimentos e Apresentação de Soluções</h4>
                    <p>
                        Oferecemos serviços de mediação de conflitos, esclarecimentos legais e apresentação de soluções para questões jurídicas complexas. Nossa equipe está preparada para resolver disputas de forma amigável e eficaz, garantindo que os interesses das partes envolvidas sejam protegidos e que os conflitos sejam resolvidos de maneira justa e equitativa.
                    </p>
                </div>
                <div className="col-block item-service" data-aos="fade-up">
                    <h4>Disponibilização da Legislação Sempre que Solicitada</h4>
                    <p>
                        Disponibilizamos acesso à legislação pertinente sempre que solicitada pelos nossos clientes, garantindo que eles estejam informados sobre as leis e regulamentos que regem as suas atividades comerciais. Nossa equipe está disponível para fornecer orientação e esclarecimentos sobre a interpretação e aplicação da legislação relevante.
                    </p>
                </div>
                <div className="col-block item-service" data-aos="fade-up">
                    <h4>Emissão e Zelo pelo Cumprimento de Normas de Compliance</h4>
                    <p>
                        Auxiliamos empresas no desenvolvimento e implementação de programas de compliance, garantindo que estas estejam em conformidade com as leis, regulamentos e padrões éticos aplicáveis. Nossa equipe oferece suporte na emissão de políticas, procedimentos e controles internos, bem como na monitorização contínua do cumprimento das normas de compliance.
                    </p>
                </div>


            </div>
        </section>
        <section id="works" className="s-works target-section">
            <div className="row section-header" data-aos="fade-up">
                <div className="col-full">
                    <h3 data-num="03" className="subhead">Nossos clientes e Parceiros</h3>
                    <h3 className="display-1">
                    Nosso compromisso com os nossos clientes e parceiros é inabalável. 
                    </h3>
                    <p>
                    Estamos dedicados a oferecer um serviço excepcional, orientado para resultados e centrado no cliente. Nossa equipe está empenhada em superar expectativas, fornecer soluções criativas e agregar valor em cada interação. Valorizamos o feedback e a colaboração contínua, pois acreditamos que é através do trabalho conjunto que alcançamos os melhores resultados    
                    </p>
                </div>
            </div>
            <div className="portfolio block-1-4 block-m-1-3 block-tab-1-2 collapse">
                <div className="col-block item-folio" data-aos="fade-up">
                    <div className="item-folio__thumb">
                        <a href="images/portfolio/gallery/g-lamp.jpg" className="thumb-link" title="Lamp" data-size="1050x700">
                            <img src="assets/images/portfolio/portfolio-1.png" srcSet="assets/images/portfolio/portfolio-1.png 1x, images/portfolio/portfolio-1.png 2x" />
                        </a>
                    </div>
                    <div className="item-folio__text">
                        <h3 className="item-folio__title">
                            Banco SOL
                        </h3>
                        <p className="item-folio__cat">
                            Banca
                        </p>
                    </div>
                    <a href="https://www.bancosol.ao" className="item-folio__project-link" title="Project link">
                        Link do clinete
                    </a>
                    <div className="item-folio__caption">
                        <p>Vero molestiae sed aut natus excepturi. Et tempora numquam. Temporibus iusto quo.Unde dolorem corrupti neque nisi.</p>
                    </div>
                </div>
                <div className="col-block item-folio" data-aos="fade-up">
                    <div className="item-folio__thumb">
                        <a href="images/portfolio/gallery/g-fuji.jpg" className="thumb-link" title="Fuji" data-size="1050x700">
                        <img src="assets/images/portfolio/portfolio-2.png" srcSet="assets/images/portfolio/portfolio-2.png 1x, images/portfolio/portfolio-2.png 2x" />
                        </a>
                    </div>
                    <div className="item-folio__text">
                        <h3 className="item-folio__title">
                            BDA
                        </h3>
                        <p className="item-folio__cat">
                            Banca
                        </p>
                    </div>
                    <a href="https://www.bda.ao" className="item-folio__project-link" title="Project link">
                        Project Link
                    </a>
                    <div className="item-folio__caption">
                        <p>Vero molestiae sed aut natus excepturi. Et tempora numquam. Temporibus iusto quo.Unde dolorem corrupti neque nisi.</p>
                    </div>
                </div>
                <div className="col-block item-folio" data-aos="fade-up">
                    <div className="item-folio__thumb">
                        <a href="images/portfolio/gallery/g-woodcraft.jpg" className="thumb-link" title="Woodcraft" data-size="1050x700">
                        <img src="assets/images/portfolio/portfolio-3.png" srcSet="assets/images/portfolio/portfolio-3.png 1x, images/portfolio/portfolio-3.png 2x" />
                        </a>
                    </div>
                    <div className="item-folio__text">
                        <h3 className="item-folio__title">
                            SANEP
                        </h3>
                        <p className="item-folio__cat">
                            group
                        </p>
                    </div>
                    <a href="https://www.sanep.ao/" className="item-folio__project-link" title="Project link">
                        Project Link
                    </a>
                    <div className="item-folio__caption">
                        <p>Vero molestiae sed aut natus excepturi. Et tempora numquam. Temporibus iusto quo.Unde dolorem corrupti neque nisi.</p>
                    </div>
                </div>
                <div className="col-block item-folio" data-aos="fade-up">
                    <div className="item-folio__thumb">
                        <a href="images/portfolio/gallery/g-droplet.jpg" className="thumb-link" title="Droplet" data-size="1050x700">
                        <img src="assets/images/portfolio/portfolio-4.png" srcSet="assets/images/portfolio/portfolio-4.png 1x, images/portfolio/portfolio-4.png 2x" />
                        </a>
                    </div>
                    <div className="item-folio__text">
                        <h3 className="item-folio__title">
                            Hiperbite
                        </h3>
                        <p className="item-folio__cat">
                            consultoria em IT 
                        </p>
                    </div>
                    <a href="https://www.hiperbite.ao/" className="item-folio__project-link" title="Project link">
                        Project Link
                    </a>
                    <div className="item-folio__caption">
                        <p>Vero molestiae sed aut natus excepturi. Et tempora numquam. Temporibus iusto quo.Unde dolorem corrupti neque nisi.</p>
                    </div>
                </div>
                <div className="col-block item-folio" data-aos="fade-up">
                    <div className="item-folio__thumb">
                        <a href="images/portfolio/gallery/g-shutterbug.jpg" className="thumb-link" title="Shutterbug" data-size="1050x700">
                        <img src="assets/images/portfolio/portfolio-5.png" srcSet="assets/images/portfolio/portfolio-5.png 1x, images/portfolio/portfolio-5.png 2x" />
                        </a>
                    </div>
                    <div className="item-folio__text">
                        <h3 className="item-folio__title">
                            Altys
                        </h3>
                        <p className="item-folio__cat">
                            Avaliação imobiliária
                        </p>
                    </div>
                    <a href="https://www.behance.net/" className="item-folio__project-link" title="Project link">
                        Project Link
                    </a>
                    <div className="item-folio__caption">
                        <p>Vero molestiae sed aut natus excepturi. Et tempora numquam. Temporibus iusto quo.Unde dolorem corrupti neque nisi.</p>
                    </div>
                </div>
                
            </div>
            <div className="testimonials-wrap" data-aos="fade-up">
                <div className="row">
                    <div className="col-full testimonials-header">
                        <h2 className="h1">Oque os nossos clientes acham de nós...</h2>
                    </div>
                </div>
                <div className="row testimonials">
                    <div className="col-full testimonials__slider">
                        <div className="testimonials__slide">
                            <span className="testimonials__icon"></span>
                            <p>A LineAudit foi incrível em fornecer soluções precisas e eficientes para nossos problemas de auditoria. Sua equipe dedicada e profissional fez toda a diferença.
                            </p>
                            <div className="testimonials__author">
                                <img src="assets/images/avatars/user-01.png" alt="Author image" className="testimonials__avatar" />
                                <span className="testimonials__name">Tim Cook</span>
                                <span className="testimonials__position">Gestor Operacional, BNDNN Angola</span>
                            </div>
                        </div>
                        <div className="testimonials__slide">
                            <span className="testimonials__icon"></span>
                            <p>Estamos extremamente satisfeitos com os serviços da LineAudit. Sua abordagem personalizada e atenção aos detalhes nos fizeram sentir confiança em cada etapa do processo.
                            </p>
                            <div className="testimonials__author">
                                <img src="assets/images/avatars/user-05.png" alt="Author image" className="testimonials__avatar" />
                                <span className="testimonials__name">Sandra Dinis Pichai</span>
                                <span className="testimonials__position">CEO, Avesazuis</span>
                            </div>
                        </div>
                        <div className="testimonials__slide">
                            <span className="testimonials__icon"></span>
                            <p>Recomendo fortemente a LineAudit para qualquer empresa que precise de serviços de auditoria de alta qualidade. Eles não apenas entregam resultados excepcionais, mas também são parceiros confiáveis e acessíveis.</p>
                            <div className="testimonials__author">
                                <img src="assets/images/avatars/user-02.png" alt="Author image" className="testimonials__avatar" />
                                <span className="testimonials__name">Satya Gaspar</span>
                                <span className="testimonials__position">DTO, Hiperbite</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id="clients" className="s-clients target-section">
            <div className="row section-header" data-aos="fade-up">
                <div className="col-full">
                    <h3 data-num="04" className="subhead">CLIENTES SELECIONADOS</h3>
                    <h1 className="display-1 display-1--light">Aqui estão algumas das marcas com as quais tivemos o privilégio de trabalhar.</h1>
                </div>
            </div>
            <div className="row clients-list block-1-4 block-tab-1-3 block-mob-1-2" data-aos="fade-up">
                <div className="col-block item-client">
                    <a href="#0">
                        <img src="assets/images/clients/icon-atom.svg" />
                    </a>
                </div>
                <div className="col-block item-client">
                    <a href="#0">
                        <img src="assets/images/clients/icon-dropbox.svg" />
                    </a>
                </div>
                <div className="col-block item-client">
                    <a href="#0">
                        <img src="assets/images/clients/icon-firefox.svg" />
                    </a>
                </div>
                <div className="col-block item-client">
                    <a href="#0">
                        <img src="assets/images/clients/icon-github.svg" />
                    </a>
                </div>
                <div className="col-block item-client">
                    <a href="#0">
                        <img src="assets/images/clients/icon-mozilla.svg" />
                    </a>
                </div>
                <div className="col-block item-client">
                    <a href="#0">
                        <img src="assets/images/clients/icon-linux.svg" />
                    </a>
                </div>
                <div className="col-block item-client">
                    <a href="#0">
                        <img src="assets/images/clients/icon-ubuntu.svg" />
                    </a>
                </div>
                <div className="col-block item-client">
                    <a href="#0">
                        <img src="assets/images/clients/icon-sass.svg" />
                    </a>
                </div>
            </div>
        </section>

        <section id="stats" className="s-stats">
            <div className="row stats-block block-1-4 block-m-1-2 block-mob-full" data-aos="fade-up">
                <div className="col-block item-stats ">
                    <div className="item-stats__count">213</div>
                    <h5>Projectos Completos</h5>
                </div>
                <div className="col-block item-stats">
                    <div className="item-stats__count">79</div>
                    <h5>Clientes</h5>
                </div>
                <div className="col-block item-stats">
                    <div className="item-stats__count">335</div>
                    <h5>Aplausos e Elogios</h5>
                </div>
                <div className="col-block item-stats">
                    <div className="item-stats__count">7</div>
                    <h5>Anos de trabalho</h5>
                </div>
            </div>
        </section>

        <section id="contact" className="s-contact target-section">
            <div className="row section-header" data-aos="fade-up">
                <div className="col-full">
                    <h3 data-num="05" className="subhead">Fale connosco</h3>
                    <h1 className="display-1 display-1--light">Tem uma ideia ou um projeto épico em mente? Fale Conosco. Vamos trabalhar juntos e fazer algo incrível. Deixe-nos uma mensagem em <a href="mailto:geral@lineaudit.ao"><span
                        className="__cf_email__" data-cfemail="c7afa2ababa887b4b3a2ababa6b5e9a4a8aa"> geral@lineaudit.ao</span></a>
                    </h1>
                </div>
            </div>
            <div className="row contact-infos">
                <div className="col-five md-seven tab-full contact-address" data-aos="fade-up">
                    <h4>ONDE NOS ENCONTRAR</h4>
                    <p>
                    Avenida de Portugal<br/>
                    Belas Business Park<br/>
                    Luanda
                    </p>
                </div>
                <div className="col-three md-five tab-full contact-social" data-aos="fade-up">
                    <h4>SIGA-NOS</h4>
                    <ul className="contact-list">
                        <li><a href="#0">Facebook</a></li>
                        <li><a href="#0">Twitter</a></li>
                        <li><a href="#0">Instagram</a></li>
                    </ul>
                </div>
                <div className="col-four md-six tab-full contact-number" data-aos="fade-up">
                    <h4>Contacte-nos</h4>
                    <ul className="contact-list">
                        <li><a href="mailto:lineaudit.ao"><span className="__cf_email__"
                            data-cfemail="0d64636b624d7e796861616c7f236e6260">geral@lineaudit.ao</span></a></li>
                        <li><a href="tel:244935765374">+244 935 765 374</a></li>
                        <li><a href="tel:244935765374">+244 935 765 374</a></li>
                        <li><a href="https://lineaudit.ao"><span className="__cf_email__"
                            data-cfemail="0d64636b624d7e796861616c7f236e6260">www.lineaudit.ao</span></a></li>
                    </ul>
                </div>
            </div>
            <div className="row contact-bottom">
                <div className="col-five tab-full contact-button" data-aos="fade-up">
                    <a href="#about" className="smoothscroll btn btn-animatedbg">
                        Saiba mais sobre nós
                    </a>
                </div>
                <div className="col-six tab-full contact-subscribe" data-aos="fade-up">
                    <h4>Subscreva</h4>
                    <form id="mc-form" className="group mc-form" >
                        <input type="email" name="EMAIL" className="email" id="mc-email" placeholder="Email" required />
                        <input type="submit" name="subscribe" value="Subscreva" />
                        <label htmlFor="mc-email" className="subscribe-message"></label>
                    </form>
                </div>
            </div>
        </section>

        <footer>
            <div className="row">
                <div className="col-full cl-copyright">
                    <span>
                        Copyright &copy;
                        <script data-cfasync="false"
                            src="assets/../../cdn-cgi/scripts/5c5dd728/cloudflare-static/email-decode.min.js"></script>
                        <script>document.write(new Date().getFullYear());</script> All rights reserved | built with <i
                            className="fa fa-heart" aria-hidden="true"></i> by <a href="https://hiperbite.ao" target="_blank">www.hiperbite.ao</a>

                    </span>
                </div>
            </div>
            <div className="cl-go-top">
                <a className="smoothscroll" title="Back to Top" href="#top"></a>
            </div>
        </footer>

        <div aria-hidden="true" className="pswp" role="dialog" tabIndex={-1}>
            <div className="pswp__bg"></div>
            <div className="pswp__scroll-wrap">
                <div className="pswp__container">
                    <div className="pswp__item"></div>
                    <div className="pswp__item"></div>
                    <div className="pswp__item"></div>
                </div>
                <div className="pswp__ui pswp__ui--hidden">
                    <div className="pswp__top-bar">
                        <div className="pswp__counter"></div><button className="pswp__button pswp__button--close" title="Close (Esc)"></button> <button className="pswp__button pswp__button--share" title="Share"></button>
                        <button className="pswp__button pswp__button--fs" title="Toggle fullscreen"></button> <button className="pswp__button pswp__button--zoom" title="Zoom in/out"></button>
                        <div className="pswp__preloader">
                            <div className="pswp__preloader__icn">
                                <div className="pswp__preloader__cut">
                                    <div className="pswp__preloader__donut"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
                        <div className="pswp__share-tooltip"></div>
                    </div><button className="pswp__button pswp__button--arrow--left" title="Previous (arrow left)"></button> <button className="pswp__button pswp__button--arrow--right" title="Next (arrow right)"></button>
                    <div className="pswp__caption">
                        <div className="pswp__caption__center"></div>
                    </div>
                </div>
            </div>
        </div>

    </>
    )
}
